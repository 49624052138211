import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

interface Props {
  title?: string
  noTitleTemplate?: boolean
  description?: string
  image?: string
  pathname?: string
  article?: boolean
}

const SEO: React.FC<Props> = ({
  title,
  noTitleTemplate,
  description,
  image,
  pathname,
  article
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`
      }
      return (
        <>
          <Helmet
            title={seo.title}
            titleTemplate={!noTitleTemplate ? titleTemplate : ''}
          >
            <link rel='preconnect' href='https://use.typekit.net' />
            <link rel='stylesheet' href='https://use.typekit.net/jpf2wpw.css' />
            <meta name='description' content={seo.description} />
            <meta name='image' content={seo.image} />
            {seo.url && <meta property='og:url' content={seo.url} />}
            <meta
              property='og:type'
              content={article ? 'article' : 'website'}
            />
            {seo.title && <meta property='og:title' content={seo.title} />}
            {seo.description && (
              <meta property='og:description' content={seo.description} />
            )}
            {seo.image && <meta property='og:image' content={seo.image} />}
            <meta name='twitter:card' content='summary_large_image' />
            {twitterUsername && (
              <meta name='twitter:creator' content={twitterUsername} />
            )}
            {seo.title && <meta name='twitter:title' content={seo.title} />}
            {seo.description && (
              <meta name='twitter:description' content={seo.description} />
            )}
            {seo.image && <meta name='twitter:image' content={seo.image} />}

            <meta name='apple-mobile-web-app-capable' content='yes' />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-2048-2732.png'
              media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-1668-2388.png'
              media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-1668-2224.png'
              media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-1536-2048.png'
              media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-1242-2688.png'
              media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-1125-2436.png'
              media='(device-width: 375px) and width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-640-1136.png'
              media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-2048-2732.png'
              media='(prefers-color-scheme: dark) and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-1668-2388.png'
              media='(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-1668-2224.png'
              media='(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-1536-2048.png'
              media='(prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-1242-2688.png'
              media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-1125-2436.png'
              media='(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-828-1792.png'
              media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-1242-2208.png'
              media='(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-750-1334.png'
              media='(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
              rel='apple-touch-startup-image'
              href='/splash/apple-splash-dark-640-1136.png'
              media='(prefers-color-scheme: dark) and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO
