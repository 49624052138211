import { font } from 'services/theme'
import { textSize, Text, H1, H2, P } from 'components/primitives/Text'
import { createGlobalStyle } from 'styled-components'
import { Grid } from 'react-styled-flexboxgrid'
import { media } from 'components/primitives/Responsive'

export const GlobalStyles = createGlobalStyle`
  ${props => props.theme.cssVariables}

  * {
    margin: 0;
    padding: 0;
    touch-action: pan-x pan-y;
  }
  
  html {
    font-size: 62.5%;
  }

  body {
    height: 100vh;
    background: var(--bg);
    color: var(--text);
    font-family: ${font};
    font-size: ${textSize.regular};
    line-height: 1.6;
    overflow-x: hidden;
  }

  #__gatsby {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  ${media.maxSm} {
    ${Grid} {
      width: 100%;
    }
  }

  ${Grid} {
    box-sizing: border-box;
  }
  
  ::selection {
    background: ${props => props.theme.colors.color1};
    color: ${props => props.theme.colors.text};
  }

  ::-moz-selection {
    background: ${props => props.theme.colors.color1};
    color: ${props => props.theme.colors.text};
  }

  .wf-loading, .wf-inactive {
    ${Text}, ${H1}, ${H2}, ${P} {
      display: none;
    }
  }

  .fade-setup-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
  }

  .fade-setup-appear, .fade-setup-enter {
    opacity: 0;
    z-index: 1;
    position: absolute;
    top: 2rem;
    width: calc(100% - 6rem);
  }
  
  .fade-setup-appear-active, .fade-setup-enter-active {
    opacity: 1;
    transition: opacity 150ms linear 150ms;
  
  }
  .fade-setup-exit {
    opacity: 1;
  } 
  
  .fade-setup-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
  }
`
