import styled from 'styled-components'
import { flexboxgrid } from 'services/theme'

const diff = 0.01

export const media = {
  xs: `@media screen and (max-width: ${flexboxgrid.breakpoints.sm - diff}em)`,

  minSm: `@media screen and (min-width: ${flexboxgrid.breakpoints.sm}em)`,
  maxSm: `@media screen and (max-width: ${flexboxgrid.breakpoints.md -
    diff}em)`,

  minMd: `@media screen and (min-width: ${flexboxgrid.breakpoints.sm}em)`,
  maxMd: `@media screen and (max-width: ${flexboxgrid.breakpoints.lg -
    diff}em)`,

  lg: `@media screen and (min-width: ${flexboxgrid.breakpoints.lg}em)`
}

interface OnlyProps {
  only: keyof typeof media
  display?: string
}

export const Only = styled.span<OnlyProps>`
  display: none;

  ${props => media[props.only]} {
    display: ${props => props.display || 'inline-block'};
  }
`
